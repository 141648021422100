<template>
  <div class="area-closed-drafts" v-if="this.listClosedDrafts.length">
    <transition>
      <div class="list-drafts" v-if="showClosedDrafts">
        <div class="line" v-for="(draft, index) in this.listClosedDrafts" :key="index">
          <span>{{draft.number}}</span>
          <a class="icon-open" href="javascript:void(0)"
             @click.stop.prevent="unlock_draft(index)">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </transition>
    <div @click="tglShowClosedDrafts" class="tgl">
      <span>Закрытые </span>
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
  </div>

  <div v-scroll="handleScroll">
    <table class="big-table help-big-table" :class="{'active': showHelpHead}"
           :style="{top: positionTop + 'px'}">
      <thead>
      <tr>
        <th class="clmn1"></th>
        <th class="clmn2"></th>
        <th class="clmn3"></th>
        <th class="clmn4"></th>
        <th class="clmn5"></th>
        <th class="clmn6"></th>
        <th class="clmn7"></th>
        <th class="clmn8"></th>
        <th class="clmn9"></th>
        <th class="clmn10"></th>
        <th class="clmn11"></th>
        <th class="clmn12"></th>
        <th class="clmn-g-1" colspan="2">Красная зона</th>
        <th class="clmn-g-1" colspan="2">Жёлтая зона</th>
        <th class="clmn-g-1" colspan="2">Зелёная зона</th>
        <th class="clmn-g-1" colspan="2">1 Белая зона</th>
        <th class="clmn-g-1" colspan="2">2 Белая зона</th>
        <th class="clmn-g-1" colspan="2">3 Белая зона</th>
        <th class="clmn-g-1" colspan="2">4 Белая зона</th>
        <th class="clmn-g-1" colspan="2">5 Белая зона</th>
      </tr>
      <tr>
        <th class="clmn1">Приоритет</th>
        <th class="clmn2">Номер заказа</th>
        <th class="clmn3">Клиент</th>
        <th class="clmn4">Дата авансового платежа</th>
        <th class="clmn5">Условия оплаты аванс %</th>
        <th class="clmn6">Дата окончательной оплаты</th>
        <th class="clmn7">Дата готовности файлов</th>
        <th class="clmn8">Расчётная скорость по асистемс</th>
        <th class="clmn9">Название узкого места</th>
        <th class="clmn10">Очередь на узком месте(дней)</th>
        <th class="clmn11">Расчетна скорость по узкому месту</th>
        <th class="clmn12">Дата отгрузки заказа (асистем)</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
        <th class="clmn13">Рек. дата</th>
        <th class="clmn14">Рек. скорость</th>
      </tr>
      </thead>
    </table>
    <table class="big-table">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th colspan="2">Красная зона</th>
          <th colspan="2">Жёлтая зона</th>
          <th colspan="2">Зелёная зона</th>
          <th colspan="2">1 Белая зона</th>
          <th colspan="2">2 Белая зона</th>
          <th colspan="2">3 Белая зона</th>
          <th colspan="2">4 Белая зона</th>
          <th colspan="2">5 Белая зона</th>
        </tr>
        <tr>
          <th>Приоритет</th>
          <th>Номер заказа</th>
          <th>Клиент</th>
          <th>Дата авансового платежа</th>
          <th>Условия оплаты аванс %</th>
          <th>Дата окончательной оплаты</th>
          <th>Дата готовности файлов</th>
          <th>Расчётная скорость по асистемс</th>
          <th>Название узкого места</th>
          <th>Очередь на узком месте(дней)</th>
          <th>Расчетна скорость по узкому месту</th>
          <th>Дата отгрузки заказа (асистем)</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
          <th>Рек. дата</th>
          <th>Рек. скорость</th>
        </tr>
      </thead>

      <tbody>
        <tr :class="{'bg2': index%2 == 0, 'bg1': index%2 != 0}"
             v-for="(order, index) in orders" :key="index">
          <td class="clmn1" :title="order.priority">{{order.priority != '-' ? index+1 : '-'}}
            <a class="btn-lock icon-close" @click.stop.prevent="lock_draft(index)"
               href="javascript:void(0)" title="Скрыть черновик">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </a>
          </td>
          <td class="clmn2">{{order.number}}</td>
          <td class="clmn3">{{order.client}}</td>
          <td class="clmn4">
            <Datepicker
              v-model="order.date_avans_pay"
              locale="ru"
              :enableTimePicker="false"
              selectText="Выбрать"
              cancelText="Отмена"
              @update:modelValue="saveUserPrint(index, 'date_avans_pay')"
              format="dd.MM.yyyy"
            ></Datepicker>
          </td>
          <td class="clmn5">
            <input @change="saveUserPrint(index, 'ifpay')"
                   class="user-print" v-model="order.ifpay" locale="ru" />
          </td>
          <td class="clmn6">
            <Datepicker
              v-model="order.date_end_pay"
              locale="ru"
              :enableTimePicker="false"
              selectText="Выбрать"
              cancelText="Отмена"
              @update:modelValue="saveUserPrint(index, 'date_end_pay')"
              format="dd.MM.yyyy"
            ></Datepicker>
          </td>
          <td class="clmn7">{{order.date_files}}</td>
          <td class="clmn8">{{order.speedcalc_asystem}}</td>
          <td class="clmn9">{{order.bottleneck_name}}</td>
          <td class="clmn10" @click="showModal(order.bottleneck_name, order.id_order_head)">
            {{order.bottleneck_stack}}
          </td>
          <td class="clmn11">{{order.calc_speed_bottleneck}}</td>
          <td class="clmn12">{{order.date_shipment}}</td>
          <td class="clmn13">{{order.red_rec_date}}</td>
          <td class="clmn14">{{order.red_rec_speed}}</td>
          <td class="clmn13">{{order.yellow_rec_date}}</td>
          <td class="clmn14">{{order.yellow_rec_speed}}</td>
          <td class="clmn13">{{order.green_rec_date}}</td>
          <td class="clmn14">{{order.green_rec_speed}}</td>
          <td class="clmn13">{{order.white1_rec_date}}</td>
          <td class="clmn14">{{order.white1_rec_speed}}</td>
          <td class="clmn13">{{order.white2_rec_date}}</td>
          <td class="clmn14">{{order.white2_rec_speed}}</td>
          <td class="clmn13">{{order.white3_rec_date}}</td>
          <td class="clmn14">{{order.white3_rec_speed}}</td>
          <td class="clmn13">{{order.white4_rec_date}}</td>
          <td class="clmn14">{{order.white4_rec_speed}}</td>
          <td class="clmn13">{{order.white5_rec_date}}</td>
          <td class="clmn14">{{order.white5_rec_speed}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <transition name="modal">
    <Modal v-if="flagShowModal" @close="flagShowModal = false">
      <template v-slot:header>
        <h3>{{labelShowModal}} (Загрузка в белой зоне)</h3>
      </template>
      <template v-slot:body>
        <div class="custom-table-green">
          <div class="head-wrp " style="display:flex">
            <div class="table-head-green" style="flex:1">Дата</div>
            <div class="table-head-green" style="flex:1">
              Нагрузка ({{workdayShowModel}} в день)
            </div>
          </div>
          <div v-for="(one, index) in workloadShowModal" :key="index" class="table-body-tr">
            <div class="table-body-td" style="flex:1">{{one.work_day}}</div>
            <div class="table-body-td" style="flex:1">
              {{one.workload}}
              <span class="text-red" v-if="one.plus_workload > 0"> +{{one.plus_workload}}</span>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import Modal from '../components/help/Modal.vue';

export default {
  components: {
    Datepicker,
    Modal,
  },
  name: 'Db',
  data() {
    return {
      loader: true,
      orders: [],
      sel_field_red: 0,
      selectedDate: null,

      listClosedDrafts: [],
      showClosedDrafts: false,

      showHelpHead: false,
      positionTop: 0,

      flagShowModal: false,
      labelShowModal: '',
      workdayShowModel: 0,
      workloadShowModal: [],
      errors: [],
    };
  },
  mounted() {
    this.getOrdersDraft();
    this.startTimer();
    this.getClosedDrafts();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  methods: {
    stopTimer() {
      if (this.interval) {
        window.clearInterval(this.interval);
      }
    },
    startTimer() {
      this.stopTimer();
      this.interval = window.setInterval(() => {
        this.getOrdersDraft();
      }, 300000);
    },
    getOrdersDraft() {
      axios.get('db/orders')
        .then((res) => {
          this.orders = res.data.orders;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showModal(lbl, orderID) {
      this.flagShowModal = true;
      this.labelShowModal = lbl;

      const url = 'db/work-load-white?val=';
      axios.get(url + orderID)
        .then((res) => {
          this.workloadShowModal = res.data.items;
          this.workdayShowModel = res.data.mid;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    saveUserPrint(index, type) {
      let val = '';
      if (type === 'date_avans_pay') {
        val = this.orders[index].date_avans_pay;
        if (val === null) {
          val = '';
        } else {
          val = val.toLocaleDateString('en-US');
        }
      } else if (type === 'ifpay') {
        val = this.orders[index].ifpay;
      } else if (type === 'date_end_pay') {
        val = this.orders[index].date_end_pay;
        val = val.toLocaleDateString('en-US');
      }

      axios.post('db/save-draft-user-data', {
        id_order_head: this.orders[index].id_order_head,
        field: type,
        value: val,
      })
        .then((res) => {
          if (res.data.status === 1) {
            if (this.orders[index].date_avans_pay > 0
              && this.orders[index].ifpay > 0
              && this.orders[index].date_end_pay > 0) {
              this.getOrdersDraft();
            }
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    handleScroll() {
      // evt, el
      if (window.scrollY > 63) {
        this.showHelpHead = true;
        this.positionTop = window.scrollY;
        /*
        el.setAttribute(
          'style',
          'opacity: 1; transform: translate3d(0, -10px, 0)',
        );
        */
      } else {
        this.showHelpHead = false;
      }

      return 0;
    },
    lock_draft(index) {
      axios.post('db/lock-draft', {
        val: this.orders[index].id_order_head,
      })
        .then((res) => {
          if (res.data.status === 1) {
            this.orders.splice(index, 1);
            this.getClosedDrafts();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    unlock_draft(index) {
      axios.post('db/unlock-draft', {
        val: this.listClosedDrafts[index].id_order_head,
      })
        .then((res) => {
          if (res.data.status === 1) {
            this.listClosedDrafts.splice(index, 1);
            this.getOrdersDraft();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getClosedDrafts() {
      const url = 'db/list-closed-drafts';
      axios.get(url)
        .then((res) => {
          if (res.data.status > 0) {
            this.listClosedDrafts = res.data.drafts;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    tglShowClosedDrafts() {
      if (this.showClosedDrafts) {
        this.showClosedDrafts = false;
      } else {
        this.showClosedDrafts = true;
      }
    },
  },
};
</script>

<style scoped>
.table{width:100%}
.table-head{display:flex;}
.table-th, .table-td{flex:1; padding:2px 10px;
  border-bottom:1px solid #000; border-right:1px solid #000}

.table-head.right .table-th, .table-head.right .table-td{justify-content: flex-end;}

.table-tr{display: flex;}
.table-td input{width:100%; height: auto; padding: 0px 8px;}

.flex1{flex:1}
.flex2{flex:2}
.flex10{flex:10}

.big-table td, .big-table th{border-bottom:1px solid #7b7b7b;
  border-right:1px solid #7b7b7b; padding:2px 10px;}

.clmn-g-1{min-width:200px;}
.clmn1{min-width:100px}
.clmn2{min-width:105px}
.clmn3{min-width:170px}
.clmn4{min-width:151px}
.clmn5{min-width:101px}
.clmn6{min-width:151px}
.clmn7{min-width:110px}
.clmn8{min-width:103px}
.clmn9{min-width:103px}
.clmn10{min-width:116px}
.clmn11{min-width:102px}
.clmn12{min-width:102px}
.clmn13{min-width:102px}
.clmn14{min-width:100px}

.clmn8, .clmn11, .clmn14{text-align:right;}

.clmn4 .dp__main.dp__theme_light, .clmn6 .dp__main.dp__theme_light{width:150px !important;}

.big-table td.clmn4, .big-table td.clmn5, .big-table td.clmn6{
  padding-left: 0px;
  padding-right: 0px;
}

.clmn5 .user-print{background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-duration: 0.2s;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 6px 12px;
  padding-left: 12px;
  box-sizing: border-box;}

.user-print{width:100px !important;}

.modal-container{width:400px !important;}

.table-head-green{background: #caf4b2b3; padding: 6px 12px;}
.table-body-td{padding: 6px 12px;}
.table-body-td.error{color:red}
.table-body-tr{display:flex}
.table-body-tr:hover{background:#eee}

.custom-table-green{height: calc(100vh - 120px); overflow-y: auto; padding-right: 15px;}

.clmn10:hover{background:#fff; cursor:pointer}

.clmn1:hover{cursor: default;}

.text-red{color: #bd1515;}

.help-big-table{position:absolute; display:none; background:#dde7f3; z-index:1; transition:0.5s;}
.help-big-table.active{display:block;}

.btn-lock{float:right; cursor:pointer; transition:1s}
.btn-lock:hover{color:red}

.area-closed-drafts{position:fixed; left:0px; top:100px;
white-space:nowrap;
  z-index:2;
}
.area-closed-drafts .tgl{background: #76b9e1;
  padding: 1px 12px;
  border-radius: 0px 0px 4px 4px; cursor:pointer;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);

  display:inline-block; vertical-align:top;
  margin-left:-43px;
}
.area-closed-drafts .tgl:hover{
}
.area-closed-drafts .list-drafts{flaot:left; background:#fff;
  padding:12px 0px; max-height:270px; overflow-y:auto;
display:inline-block; vertical-align:top;
  min-width: 145px;
  padding: 12px;
  margin-top:-43px;
}
.area-closed-drafts .list-drafts .line{white-space:nowrap}

.icon-close{margin-left:12px; color:#333}
.icon-open{margin-left:12px; color:#333}
.icon-open:hover{color:green}
</style>
